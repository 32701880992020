import axios from 'axios';

const landingGame = document.querySelector('.PopinDown');

const dismissPopin = (popinId) => {
  axios({
    url: `/popin/${popinId}/dismiss`,
    method: 'POST'
  });
};

const PopInDown = () => {
  if (landingGame) {
    let isVisible = false;

    for (const closeBtn of [
      ...landingGame.querySelectorAll('[data-close-landing-game]')
    ]) {
      closeBtn.addEventListener('click', ({ currentTarget }) => {
        dismissPopin(currentTarget.dataset.popinId);
        landingGame.classList.add('hidden');
      });
    }

    const btnKnowMore = document.querySelector('[data-know-more]');

    if (btnKnowMore) {
      btnKnowMore.addEventListener('click', ({ currentTarget }) => {
        dismissPopin(currentTarget.dataset.popinId);
        landingGame.classList.add('hidden');
      });
    }

    window.addEventListener('scroll', function () {
      if (window.scrollY >= 3000 && isVisible) {
        landingGame.classList.remove('visible');
        isVisible = false;
      } else if (
        window.scrollY >= 1500 &&
        window.scrollY <= 2999 &&
        !isVisible
      ) {
        landingGame.classList.add('visible');
        isVisible = true;
      } else if (window.scrollY < 1500 && isVisible) {
        landingGame.classList.remove('visible');
        isVisible = false;
      }
    });
  }
};

export default PopInDown;
