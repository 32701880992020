import WindowState from '@utils/windowState';

const stopSummaryPropagation = (
  currentTarget,
  CARDS_WITH_NO_CONTENT,
  event
) => {
  if (!currentTarget.classList.contains(CARDS_WITH_NO_CONTENT)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

const SummaryCard = () => {
  const summaryCards = document.querySelectorAll(
    '.SummaryCard:not(.noContent)'
  );
  const CARDS_WITH_NO_CONTENT = 'noContent';
  const CARDS_OPEN = 'open';

  summaryCards.forEach((summaryCard) => {
    summaryCard.addEventListener('click', (event) => {
      const { currentTarget } = event;

      if (!WindowState.TABLET_STATE.get()) {
        if (!currentTarget.classList?.contains(CARDS_OPEN)) {
          currentTarget.classList.add(CARDS_OPEN);

          // Permet de savoir avant le raffraichissement de la page la taille que va posséder l'élément pour l'animation
          requestAnimationFrame(() => {
            currentTarget.style.maxHeight = currentTarget.scrollHeight + 'px';
          });
        } else {
          currentTarget.classList.remove(CARDS_OPEN);

          // Reset de la taille.
          requestAnimationFrame(() => {
            currentTarget.style.maxHeight = '9.375rem';
          });
        }
      } else {
        const overlay = document.querySelector('.OverlaySummary');

        stopSummaryPropagation(currentTarget, CARDS_WITH_NO_CONTENT, event);

        if (overlay) {
          overlay.classList.toggle(
            CARDS_OPEN,
            currentTarget.dataset.overlayState === CARDS_OPEN ||
              currentTarget.classList.contains('js-overlaySummaryOpen')
          );

          const content = document.querySelector(
            `.js-summaryContentOverlay.${currentTarget.id}`
          );

          const contentOverlay = document.querySelector(
            '.js-hydrateOverlaySummary'
          );

          contentOverlay.innerHTML = content.innerHTML;
        }
      }
    });
  });

  const overlaySummary = document.querySelector('.js-OverlaySummary');

  if (overlaySummary) {
    overlaySummary.addEventListener('click', (_) => {
      const overlay = document.querySelector('.OverlaySummary');

      if (overlay && overlay.classList.contains(CARDS_OPEN)) {
        overlay.classList.remove(CARDS_OPEN);
      }
    });
  }
};

export default SummaryCard;
