const observeStickyElement = (element, classToToggle, callback) => {
  const observer = new IntersectionObserver(
    ([e]) => {
      const IS_STUCK = e.intersectionRatio < 1 && e.boundingClientRect.y <= 0;
      e.target.classList.toggle(classToToggle, IS_STUCK);

      if (callback) {
        callback(e, IS_STUCK);
      }
    },
    { threshold: [1] }
  );

  observer.observe(element);
};

export default observeStickyElement;
