import { load } from 'recaptcha-v3';

export async function verifyRecaptcha(form) {
  const dataElement = form.querySelector('.g-recaptcha');

  try {
    if (!dataElement) return;

    const { sitekey, size } = dataElement.dataset;
    const id = dataElement.id;

    if (!sitekey) return;

    const recaptcha = await load(sitekey, {
      autoHideBadge: true,
      container: id,
      size
    });

    return recaptcha.execute().then((token) => {
      if (token) {
        const input = document.createElement('input');

        input.type = 'hidden';
        input.value = token;
        input.name = 'g-recaptcha-response';

        form.appendChild(input);

        return true;
      }

      throw new Error('Le captcha est invalide');
    });
  } catch (error) {
    throw new Error('Le captcha est invalide');
  }
}
