const { verifyRecaptcha } = require('@js/utils/Recaptcha');

const initRecaptcha = () => {
  const form = document.querySelector('#form-persona');

  if (!form) return;

  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    try {
      await verifyRecaptcha(form);
    } catch (error) {
      const alert = document.querySelector('.js-alertRegister');

      if (!alert) return;

      alert.classList.remove('hidden');
      alert.innerHTML = error.message;

      return;
    }

    form.submit();
  });
};

const PopInPersona = () => {
  initRecaptcha();
};

export default PopInPersona;
