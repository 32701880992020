import '../css/tailwind-base.css';
import '../css/app.css';
import '../css/tailwind-utilities.css';

import AccountHeader from '@components/AccountHeader/AccountHeader';
import CycleCard from '@components/CycleCard/CycleCard';
import Header from '@components/Header/Header';
import HeaderPage from '@components/HeaderPage/HeaderPage';
import HeaderSearchAction from '@components/HeaderSearchAction/HeaderSearchAction';
import LeftNavDropDown from '@components/LeftNav/LeftNavDropDown/LeftNavDropdown';
import LeftNavItem from '@components/LeftNav/LeftNavItem';
import ManageDataLayer from '@components/ManageDataLayer/ManageDataLayer';
import TheliaAi from '@components/TheliaAi/TheliaAi';
import OverlayContact from '@components/OverlayContact/OverlayContact';
import PickupDate from '@components/PickupDate/PickupDate';
import ProductSlides from '@components/ProductSlide/ProductSlide';
import Rebound from '@components/Rebound/Rebound';
import SearchHeader from '@components/SearchHeader/SearchHeader';
import Select from '@components/Select/Select';
import ServiceModule from '@components/ServiceModule/ServiceModule';
import SummaryCard from '@components/SummaryCard/SummaryCard';
import SvgAjax from '@utils/SvgAjax';
import Tabs from '@components/Tabs/Tabs';
import Testimonial from '@components/Testimonial/Testimonial';
import axios from 'axios';
import PopInDown from '@components/PopinDown/PopinDown';
import PopInPersona from '@components/PopinPersona/PopInPersona';
import TopBar from '@components/TopBar/TopBar';

import {
  initCookieAtStartWhenLogged,
  listenToEmailValueSubmitting
} from './utils/SessionCookies';

function main() {
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  // utils
  SvgAjax();

  //Nav
  Header();

  // components
  Tabs();
  PickupDate();
  Select();
  LeftNavDropDown();
  Rebound();
  SummaryCard();
  AccountHeader();

  ProductSlides();

  ServiceModule();
  HeaderPage();
  LeftNavItem();
  SearchHeader();
  HeaderSearchAction();
  CycleCard();
  OverlayContact();

  Testimonial();
  ManageDataLayer();
  TheliaAi();
  PopInDown();
  PopInPersona();
  TopBar();
  listenToEmailValueSubmitting();
  initCookieAtStartWhenLogged();
}

export const TheliaJS = {
  axios: axios
};

global.TheliaJS = TheliaJS;

document.addEventListener('DOMContentLoaded', () => {
  main();
});
