import manageClickOutside from '@utils/manageClickOutside';

const contentPage = document.querySelector('.CycleCardContainer');

const toggleOverlay = (currentTarget = null, needToRemove = false) => {
  let cardOverlay = null;

  if (!currentTarget) {
    cardOverlay = document.querySelector('.js-cycleCardOverlay.open');
  } else {
    cardOverlay = document.querySelector(`#${currentTarget.dataset.id}`);
  }

  if (!cardOverlay) return;

  cardOverlay.classList.toggle('open');
  document.body.classList.toggle('overflow-hidden');
  document.body.classList.toggle('is-open');

  const listenerFunction = (event) =>
    manageClickOutside(
      event,
      '.js-cycleCard',
      '.js-cycleCardOverlay',
      toggleOverlay
    );

  if (!needToRemove) {
    contentPage.addEventListener('click', listenerFunction);
  } else {
    contentPage.removeEventListener('click', listenerFunction);
    document.body.classList.remove('is-open');
    document.body.classList.remove('overflow-hidden');
  }
};

const CycleCard = () => {
  const cards = document.querySelectorAll('.js-cycleCard');
  const cardsButtonClose = document.querySelectorAll('.js-cycleCardClose');

  if (!cards || !cardsButtonClose) return;

  cards.forEach((card) =>
    card.addEventListener('click', ({ currentTarget }) => {
      const alreadyOpen = document.querySelector('.js-cycleCardOverlay.open');

      if (alreadyOpen) {
        alreadyOpen.classList.remove('open');
        document.body.classList.remove('overflow-hidden');
        document.body.classList.remove('is-open');
        return;
      }

      toggleOverlay(currentTarget);
    })
  );

  cardsButtonClose.forEach((card) =>
    card.addEventListener('click', ({ currentTarget }) =>
      toggleOverlay(currentTarget)
    )
  );
};

export default CycleCard;
