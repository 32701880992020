import parseTailwindBreakpoint from '@utils/parseTailwindBreakpoint';

const BREAKPOINT = parseTailwindBreakpoint();

let isMobile = window.innerWidth < BREAKPOINT.md;
let isTablet = window.innerWidth < BREAKPOINT.lg;
let isBelowXl = window.innerWidth < BREAKPOINT.xl;

const TABLET_STATE = {
  get: () => {
    return isTablet;
  },
  set: (value) => {
    isTablet = value;
  }
};
const BELOW_XL_SCREEN = {
  get: () => {
    return isBelowXl;
  },
  set: (value) => {
    isBelowXl = value;
  }
};
const MOBILE_STATE = {
  get: () => {
    return isMobile;
  },
  set: (value) => {
    isMobile = value;
  }
};

const WindowState = {
  MOBILE_STATE,
  TABLET_STATE,
  BELOW_XL_SCREEN
};

window.addEventListener('resize', () => {
  TABLET_STATE.set(window.innerWidth < BREAKPOINT.lg);
  BELOW_XL_SCREEN.set(window.innerWidth < BREAKPOINT.xl);
  MOBILE_STATE.set(window.innerWidth < BREAKPOINT.md);
});

export default WindowState;
