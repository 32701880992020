import axios from 'axios';
import { verifyRecaptcha } from '@js/utils/Recaptcha';
import { createCookie, getKeyEmailFormData } from '@js/utils/SessionCookies';

let hasInputHidden = false;
let salesmanId = null;

const manageOverlay = () => {
  const overlay = document.querySelector('.js-ctaForm');
  const buttonOpen = document.querySelectorAll('.js-openOverlayCta');
  const buttonClose = document.querySelectorAll('.js-closeOverlayCta');

  const toggleOverlay = () => {
    if (overlay) {
      overlay.classList.toggle('open');
      toggleFormSuccess(false);
      resetErrorLabel();

      window.dataLayer.push({
        pageTheme: 'WEBCALLBACK'
      });

      //block scroll
      document.body.classList.toggle('hasOverlayOpen');
    }
  };

  const manageInputDisplay = (type = null) => {
    const inputSalesmanBehavior = document.querySelectorAll(
      '.js-salesmanBehavior'
    );

    if (inputSalesmanBehavior) {
      inputSalesmanBehavior.forEach((input) => {
        input.classList.toggle('hidden');

        if (input.classList.contains('js-askingAbout') && type) {
          const option = input.querySelector(`option[value=${type}]`);
          if (!option) return;

          option.selected = true;
        } else {
          const option = input.querySelector(`.js-askingAbout option:checked`);

          if (!option) return;

          option.selected = false;
        }
      });
    }

    hasInputHidden = !hasInputHidden;
  };

  if (buttonOpen) {
    buttonOpen.forEach((btn) => {
      btn.addEventListener('click', () => {
        toggleOverlay();
      });
    });
  }

  if (buttonClose) {
    buttonClose.forEach((btn) => {
      btn.addEventListener('click', () => {
        if (hasInputHidden) {
          manageInputDisplay();
        }

        toggleOverlay();
      });
    });
  }

  // manage salesman overlay open
  const buttonsSalesman = document.querySelectorAll('.js-salesmanCall');

  if (!buttonsSalesman) return;

  buttonsSalesman.forEach((btn) => {
    btn.addEventListener('click', ({ currentTarget }) => {
      if (parseInt(currentTarget.dataset.salesmanId, 10)) {
        salesmanId = currentTarget.dataset.salesmanId;

        manageInputDisplay(currentTarget.dataset.salesmanType);
      }

      toggleOverlay();
    });
  });
};

const manageErrorLabel = (value, type) => {
  if (value) {
    const labelError = document.querySelector(`#error-${type}`);

    labelError.innerHTML = value;
  }
};

const resetErrorLabel = () => {
  document.querySelectorAll('.js-errorLabel').forEach((errorLabel) => {
    errorLabel.innerHTML = '';
  });

  const alert = document.querySelector('.js-alertWebCallback');

  if (!alert) return;

  alert.classList.add('hidden');
  alert.innerHTML = '';
};

const toggleFormSuccess = (isSuccess = true) => {
  let CLASS_TO_REPLACE = 'hidden';
  let CLASS_REPLACED_BY = 'flex';

  const formContainer = document.querySelector('.js-ctaOverlayFormContainer');
  const successElement = document.querySelector('.js-ctaOverlayFormSuccess');

  if (!isSuccess) {
    CLASS_TO_REPLACE = 'flex';
    CLASS_REPLACED_BY = 'hidden';
  }

  formContainer.classList.toggle('hidden', isSuccess);
  successElement.classList.replace(CLASS_TO_REPLACE, CLASS_REPLACED_BY);
};

const manageWebCallbackForm = () => {
  const formWebCallback = document.querySelector('.js-webCallbackForm');

  if (formWebCallback) {
    formWebCallback.addEventListener('submit', async (e) => {
      e.preventDefault();
      e.stopPropagation();
      resetErrorLabel();

      if (!document.querySelector('.js-webCallbackForm').reportValidity())
        return;

      const form = e.currentTarget;

      try {
        await verifyRecaptcha(form);
      } catch (error) {
        const alert = document.querySelector('.js-alertWebCallback');

        if (!alert) return;

        alert.classList.remove('hidden');
        alert.innerHTML = error.message;

        return;
      }

      const formData = form ? new FormData(form) : new FormData();

      const retrivedKey = getKeyEmailFormData(formData);

      if (retrivedKey) {
        createCookie(formData.get(retrivedKey), '_realtimeasset');
      }

      if (salesmanId) {
        formData.append(
          'lgseeds_form_front_web_callback_form[salesman_id]',
          salesmanId
        );
      }

      try {
        const response = await axios.post('/web_callback', formData);

        if (response) {
          window.dataLayer.push({
            event: 'WEBCALLBACK',
            action: 'submit'
          });

          toggleFormSuccess();

          if (window.MsCrmMkt) {
            window.MsCrmMkt.MsCrmFormLoader.sendFormCaptureToCrm(form);
          }
        }
      } catch (error) {
        const { errors, error: errorMessage } = error.response.data;

        if (errors) {
          const errorsList = [
            { value: errors.availability_day, type: 'availability_day' },
            { value: errors.availability_hours, type: 'availability_hours' },
            { value: errors.firstname, type: 'firstname' },
            { value: errors.lastname, type: 'lastname' },
            { value: errors.email, type: 'email' },
            { value: errors.phone, type: 'phone' },
            { value: errors.profile, type: 'profile' },
            { value: errors.update_data, type: 'update_data' }
          ];

          errorsList.forEach((error) =>
            manageErrorLabel(error.value, error.type)
          );
        }

        if (errorMessage) {
          const alert = document.querySelector('.js-alertWebCallback');

          if (!alert) return;

          alert.classList.remove('hidden');
          alert.innerHTML = errorMessage;
        }
      }
    });
  }
};

const ServiceWebCallback = () => {
  manageOverlay();
  manageWebCallbackForm();
};

export default ServiceWebCallback;
