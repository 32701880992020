const manageWhenOpened = (element) => {
  const content = element.querySelector('.LeftNavDropDown-content');
  const minusIcon = element.querySelector('.js-minusIcon');
  const plusIcon = element.querySelector('.js-plusIcon');

  if (element.classList.contains('is-opened')) {
    content.classList.toggle('open');
    minusIcon.classList.add('hidden');
    plusIcon.classList.remove('hidden');
  } else {
    content.classList.toggle('open');
    minusIcon.classList.remove('hidden');
    plusIcon.classList.add('hidden');
  }
};

const LeftNavDropDown = async () => {
  const dropdowns = document.querySelectorAll('.LeftNavDropDown');

  dropdowns.forEach((dropdown) => {
    manageWhenOpened(dropdown);

    dropdown.addEventListener('click', ({ currentTarget, target }) => {
      if (!target.closest('.LeftNavDropDown-item')) return;

      currentTarget.classList.toggle('is-opened');

      manageWhenOpened(currentTarget);
    });
  });
};

export default LeftNavDropDown;
