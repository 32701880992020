import Slugify from '@js/utils/Slugify';
import getTopElement from '@utils/getTopElement';

const scrollTo = (target) => {
  const headerSite = document.querySelector('#Header');

  const elemTarget = document.querySelector(target);
  const elementPosition = getTopElement(elemTarget);
  const offsetPosition = elementPosition - headerSite.offsetHeight;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
};

const getAnchorTitle = () => {
  const anchors = document.querySelectorAll('.js-article>h2');

  if (!anchors) return;

  return anchors;
};

const hydrateWithLeftNavItem = (titleList = []) => {
  const anchorsDisplay = document.querySelector('.js-articleAnchors');
  const anchorsTemplate = document.querySelector(
    '.js-articleAnchors>.LeftNavItem'
  );

  if (!anchorsTemplate || !anchorsDisplay) return;

  titleList.forEach((title) => {
    const { innerHTML } = title;
    const slugId = Slugify(innerHTML);

    const child = anchorsTemplate.cloneNode(true);

    title.id = slugId;

    child.innerHTML = child.innerHTML.replace('[label]', innerHTML);
    child.dataset.id = slugId;
    child.hash = slugId;
    child.classList.remove('hidden');

    anchorsDisplay.append(child);
  });
};

const initNavItem = () => {
  const titleList = getAnchorTitle();

  if (!titleList) return;

  hydrateWithLeftNavItem(titleList);

  const anchorList = document.querySelectorAll(
    `.js-articleAnchors>*:not(.hidden)`
  );

  if (!anchorList) return;

  anchorList.forEach((anchor) => {
    anchor.addEventListener('click', (event) => {
      if (!anchor.hash) return;

      event.preventDefault();

      scrollTo(anchor.hash);

      const uri = `${window.location.href.split('#')[0]}${anchor.hash}`;
      global.history.replaceState({}, 'recherche', uri);

      activateNav(event.currentTarget.dataset.id);
    });
  });
};

const activateNav = (id = null) => {
  const current_id = id ? id : window.location.hash.substring(1);

  const anchor = document.querySelector(
    `.js-articleAnchors>[data-id="${current_id}"]`
  );

  const anchorActivated = document.querySelector(`.js-articleAnchors>.active`);

  if (!anchor) return;

  if (anchorActivated) {
    anchorActivated.classList.remove('active');
  }

  anchor.classList.add('active');
  scrollTo(anchor.hash);
};

const LeftNavItem = () => {
  initNavItem();

  activateNav();
};

export default LeftNavItem;
