const TYPE = {
  SEMENCES: 'semences',
  CONTENTS: 'contents',
  TRY_OUT: 'tryouts'
};

let isFirstDisplay = false;

/**
 *
 *
 * @param {string} elementClass
 * @param {Element} elementAlreadyActive
 * @return {*}
 */
const toggleElement = (elementClass, elementAlreadyActive) => {
  const alreadyActive = document.querySelector(elementAlreadyActive);
  const element = document.querySelector(elementClass);

  if (!element) return;

  if (alreadyActive) {
    alreadyActive.classList.remove('active');

    const tabsAlreadyActive = document.querySelector(
      `#${alreadyActive.dataset.type}.active`
    );

    if (tabsAlreadyActive) {
      tabsAlreadyActive.classList.remove('active');
    }
  }

  element.classList.toggle('active');
};

const manageContent = (type) => {
  if (window.DISPLAY_FIRST && !isFirstDisplay) {
    type = window.DISPLAY_FIRST;
    isFirstDisplay = true;
  } else if (!type) {
    if (parseInt(window.SEMENCES_COUNT, 10) > 0) {
      type = TYPE.SEMENCES;
    } else if (parseInt(window.CONTENTS_COUNT, 10) > 0) {
      type = TYPE.CONTENTS;
    } else if (parseInt(window.TRYOUTS_COUNT, 10) > 0) {
      type = TYPE.TRY_OUT;
    }
  }

  toggleElement(
    `.js-searchResultsItems[data-type="${type}"]`,
    '.js-searchResultsItems.active'
  );

  toggleElement(`#${type}`, `.js-searchTab`);

  const tabNode = document.querySelector(
    `.js-searchResultsItems[data-type="${type}"]`
  );

  if (tabNode) {
    tabNode.dispatchEvent(new CustomEvent('display-search-tab'));
  }
};

const initTab = () => {
  const tabs = document.querySelectorAll('.js-searchTab');

  if (!tabs) return;

  manageContent();

  tabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      manageContent(tab.id);
    });
  });
};

const SearchHeader = () => {
  initTab();
};

export default SearchHeader;
