import '../../node_modules/slim-select/dist/slimselect.min.css';

import SlimSelect from 'slim-select';

const searchableSelect = () => {
  const selects = document.querySelectorAll(
    '.Select:not([multiple]):not([data-ssid]):not(#culture_test):not(.ss-main)'
  );
  selects.forEach((select) => {
    const needSearch = select.classList.contains('Select--searchable');
    const slimSelect = new SlimSelect({
      select,
      showSearch: needSearch,
      placeholder: ' ',
      allowDeselectOption: true,
      searchPlaceholder: select.dataset.searchplaceholder,
      onChange: (info) => {
        if (this?.styleValidity) {
          this.styleValidity();
        }
      }
    });

    if (!slimSelect) return;

    slimSelect.styleValidity = function (state) {
      if (state == null) {
        state = this.select.element.checkValidity();
      }
      this.render.container.classList.toggle('ss-invalid', !state);
    };
  });
};

const multipleSelect = () => {
  const selects = document.querySelectorAll('.Select[multiple]:not(#culture_test)');

  selects.forEach((select) => {
    new SlimSelect({
      select,
      placeholder: ' ',
      allowDeselectOption: true,
      searchPlaceholder: select.dataset.searchplaceholder,
      limit: 3,
      hideSelectedOption: true,
      showSearch: select.classList.contains('Select--searchable')
    });
  });
};

const Select = async () => {
  const selects = document.querySelectorAll('.Select');

  const moveLabelToTheTop = (currentTarget) => {
    const options = currentTarget.querySelectorAll('option');

    if (options.length > 0) {
      let selected = null;

      currentTarget.classList.remove('selectedValue');

      options.forEach((option) => {
        if (option.selected) {
          selected = option;
        }
      });

      if (selected && selected.value) {
        currentTarget.classList.add('selectedValue');
      }
    }
  };

  if (selects.length > 0) {
    selects.forEach((select) => {
      moveLabelToTheTop(select);

      select.addEventListener('change', ({ currentTarget }) => {
        moveLabelToTheTop(currentTarget);
      });
    });
  }

  searchableSelect();
  multipleSelect();
};

export default Select;
