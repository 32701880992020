import { verifyRecaptcha } from '@js/utils/Recaptcha';
import { createCookie, getKeyEmailFormData } from '@js/utils/SessionCookies';
import CallToActionService from './CallToActionService';
import FIELDS from './fields';

// Variable intitialization needed on multiple function.
const CTA_ID_DEFAULT = 0;
const formCallToAction = document.querySelectorAll(`.${FIELDS.form.class}`);
const callToActionArchive = {};

const submitForm = async (form, callToActionId = CTA_ID_DEFAULT) => {
  const formData = form ? new FormData(form) : new FormData();

  const retrivedKey = getKeyEmailFormData(formData);

  if (retrivedKey) {
    createCookie(formData.get(retrivedKey), '_realtimeasset');
  }

  try {
    const response = await CallToActionService.postCTA(
      formData,
      callToActionId
    );

    if (response.errors) {
      CallToActionService.toggleFormErrors(
        response.errors,
        response.message,
        form
      );
      return;
    }
    try {
      const formDataEmailValue = formData.get(
        `lgseeds_form_front_customer_call_to_action_form[email]`
      );

      let uriEmailParam = '';

      if (formDataEmailValue) {
        uriEmailParam = `?email=${encodeURIComponent(formDataEmailValue)}`;
      }

      CallToActionService.getRessource(
        callToActionId,
        uriEmailParam,
        callToActionArchive[callToActionId] === 'download',
        form.title
      );
      CallToActionService.toggleFormSuccess(form);
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Manage state of the CTA form
 * * manage display of needed field
 *
 * @param {*} fields
 * @param {*} form
 * @param {*} parentSectionForm
 * @param {*} [otherFields=null]
 * @param {boolean} [needSubmit=true]
 * @return {*}
 */
const manageCTAForm = (
  callToActionId,
  fields,
  form,
  parentSectionForm,
  otherFields = null,
  needSubmit = true
) => {
  const fieldsListToToggle = [];

  if (!fields || !form || !parentSectionForm) return;

  // it means the customer answers all the questions
  if (fields.length === 0) {
    debugger;
    submitForm(form, callToActionId);
    return;
  }

  const alert = parentSectionForm.querySelector('.js-ctaFormError');

  if (alert) {
    alert.classList.add('hidden');
  }

  if (needSubmit) {
    const formCallToActionSubmit = parentSectionForm.querySelector(
      `.${FIELDS.submit.class}`
    );

    if (formCallToActionSubmit) fieldsListToToggle.push(formCallToActionSubmit);
  }

  if (otherFields) {
    otherFields.forEach((otherField) => {
      fieldsListToToggle.push(otherField);
    });
  }

  fields.forEach((fieldResponse) => {
    if (FIELDS[fieldResponse]) {
      fieldsListToToggle.push(
        parentSectionForm.querySelector(`.${FIELDS[fieldResponse].class}`)
      );
    }
  });

  CallToActionService.toggleFormFields(fieldsListToToggle, 'hidden');
};

/**
 * Manage the content of the cta container
 *
 * @return {*}
 */
const manageCTAContent = () => {
  const buttonsCta = document.querySelectorAll('.js-callToActionButton');

  if (!buttonsCta || buttonsCta.length < 1) return;

  for (const buttonCta of buttonsCta) {
    // on va travailler depuis la section parent pour les futures références de tag HTML
    const parentSectionCTA = buttonCta.closest('section.ServiceModule');
    // Manage the start of the cta form
    buttonCta.addEventListener('click', async ({ currentTarget }) => {
      const callToActionId = currentTarget.dataset.callToActionId;
      callToActionArchive[`${callToActionId}`] =
        currentTarget.dataset.resource_type;

      const isConnected = parseInt(currentTarget.dataset.isConnected, 10) === 1;

      // if Cta download else subscription
      if (currentTarget.dataset.resource_type !== 'magazine') {
        const formSectionCTA = parentSectionCTA.querySelector(
          `.${FIELDS.form.class}`
        );
        if (formSectionCTA) {
          await CallToActionService.manageDefaultCta(
            formSectionCTA,
            parentSectionCTA,
            buttonCta,
            isConnected,
            callToActionId,
            manageCTAForm
          );
        }
      } else {
        if (!isConnected) {
          const emailForm = parentSectionCTA.querySelector('.js-emailForm');
          const content = parentSectionCTA.querySelector(
            `.${FIELDS.content.class}`
          );
          const formContainer = parentSectionCTA.querySelector(
            `.${FIELDS.form_container.class}`
          );

          emailForm.classList.toggle('hidden');
          formContainer.classList.toggle('hidden');
          content.classList.toggle('hidden');

          // demander mail
          emailForm.addEventListener('submit', async (event) => {
            event.preventDefault();
            event.stopPropagation();

            const formData = new FormData(emailForm);
            const email = formData.get('email-for-magazine');

            // appel api
            const typeForm = await CallToActionService.getMagazine(
              callToActionId,
              `?email=${encodeURIComponent(email)}`
            );

            if (!typeForm) return;

            // display register / login
            let formToShow = null;

            if (typeForm === 'register') {
              formToShow = parentSectionCTA.querySelector(
                '.js-subscribeRegisterForm'
              );
              CallToActionService.initFormRegister(formToShow);
            } else if (typeForm === 'login') {
              formToShow = parentSectionCTA.querySelector(
                '.js-subscribeLoginForm'
              );
            }

            emailForm.classList.toggle('hidden');

            if (!formToShow) return;

            formToShow.classList.toggle('hidden');

            // Init la vérification du captcha quand on affiche le form.
            formToShow.addEventListener('submit', async (event) => {
              event.preventDefault();

              try {
                await verifyRecaptcha(formToShow);
              } catch (error) {
                const alert =
                  parentSectionCTA.querySelector('.js-alertRegister');

                if (!alert) return;

                alert.classList.remove('hidden');
                alert.innerHTML = error.message;

                return;
              }

              formToShow.submit();
            });

            const emailInput = formToShow.querySelector('input#email');
            emailInput.value = email;

            if (typeForm === 'register') {
              formToShow.classList.toggle('flex');
            }
          });
        }
      }
    });
  }

  // Manage submit of the form
  if (!formCallToAction || formCallToAction.length < 1) return;

  for (const formCTA of formCallToAction) {
    formCTA.addEventListener('submit', async (event) => {
      const callToActionId = event.target.querySelector(
        'input[name="lgseeds_form_front_customer_call_to_action_form[call_to_action_id]"]'
      );
      console.log(callToActionId, callToActionId.value);

      event.preventDefault();
      event.stopPropagation();

      // current.target is form
      debugger;
      submitForm(
        event.currentTarget,
        callToActionId.value ? callToActionId.value : undefined
      );
    });
  }
};

/**
 * Root
 *
 */
const ServiceCta = () => {
  for (const currentForm of formCallToAction) {
    CallToActionService.disableFormRequired(currentForm);
  }
  manageCTAContent();
};

export default ServiceCta;
