import WindowState from '../../assets/js/utils/windowState';
import axios from 'axios';

const API = {
  SEARCH: '/elasticsearch/query?q='
};

const SearchAction = {
  SHOW_LIMIT: 4,
  ACTION: '/search',
  BTN_ALL: {
    product: '.js-allSemences',
    contents: '.js-allContents',
    tryouts: '.js-allTryouts'
  },
  CLASSES: {
    CLASS_TO_HIDDEN: 'hidden',
    DROPDOWN_CLASS: '.js-resultsDropdown',
    ACTIVE_CLASS: 'active',
    SEMENCES_CONTAINER: '.js-semencesContainer',
    SEMENCES_TEMPLATE: '.js-templateSemences',
    CONTENTS_CONTAINER: '.js-contentsContainer',
    CONTENTS_TEMPLATE: '.js-templateContents',
    TRYOUTS_CONTAINER: '.js-tryoutsContainer',
    TRYOUTS_TEMPLATE: '.js-templateTryouts',
    SEARCH_INPUT: '.js-searchAction',
    LOADER: '.js-loader',
    SEARCH_RESULT: '.SearchResults',
    FLEX: 'flex',
    NO_CONTENT: '.js-noContent'
  },
  MIN_SEARCH_CHARACTER: 3,
  EVENT_TYPE: {
    ENTER: 'Enter',
    KEY_UP: 'keyup',
    CLICK: 'click'
  },
  DROPDOWN_STATUS: {
    OPEN: 'add',
    CLOSE: 'remove'
  },
  ELEMENT_TYPE: {
    SEMENCES: 'semences',
    CONTENTS: 'contents',
    TRYOUTS: 'tryouts'
  }
};

const createInput = (value, name) => {
  const input = document.createElement('input');

  input.setAttribute('type', 'text');
  input.setAttribute('name', name);
  input.setAttribute('value', value);

  return input;
};

const toggleBtnAll = (type, status) => {
  const btnAll = document.querySelector(`.js-moreSearch[data-type="${type}"]`);

  if (!btnAll) return;

  if (status === 'add') {
    btnAll.classList.add(SearchAction.CLASSES.CLASS_TO_HIDDEN);

    return;
  }

  btnAll.classList.remove(SearchAction.CLASSES.CLASS_TO_HIDDEN);
};

const SearchActionService = {
  SearchAction: SearchAction,
  /**
   *
   * @param string searchValue
   * @returns {*} data
   */
  search: async (searchValue) => {
    const { data } = await axios.get(API.SEARCH + searchValue);

    return data;
  },
  actionSearchSubmit: async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { currentTarget } = event;

    const type = currentTarget.dataset.type;

    const trial = {
      id: currentTarget.dataset?.id,
      type: currentTarget.dataset.trialsType
    };

    const input = document.querySelector(
      `${SearchAction.CLASSES.SEARCH_INPUT}[data-window-state="${
        WindowState.TABLET_STATE.get() ? 'mobile' : 'desktop'
      }"]`
    );
    const data = await SearchActionService.search(input.value);

    SearchActionService.searchSubmit(input.value, data, type, trial);
  },
  /**
   *
   * @param string value
   * @param {*} data
   * @param string displayFirst
   * @returns
   */
  searchSubmit: (value, data, displayFirst, trial) => {
    if (!value || !data) return;

    // submit post avec la value
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', `${SearchAction.ACTION}?q=${value}`);

    const semencesCount = data.products?.length ?? 0;
    const contentsCount = data.contents?.length ?? 0;
    const tryoutsCount = data.trials?.length ?? 0;

    const searchTotal = semencesCount + contentsCount + tryoutsCount;

    const inputs = [
      { value, name: 'searchedValue' },
      { value: searchTotal, name: 'searchTotal' },
      { value: semencesCount, name: 'searchSemencesTotal' },
      { value: contentsCount, name: 'searchContentsTotal' },
      { value: tryoutsCount, name: 'searchTryOutsTotal' },
      { value: JSON.stringify(data.trials), name: 'tryouts' }
    ];

    if (displayFirst) {
      inputs.push({ value: displayFirst, name: 'displayFirst' });
    }

    if (trial) {
      inputs.push({
        value: JSON.stringify(trial),
        name: 'searchCurrentTryouts'
      });
    }

    inputs.forEach(({ value, name }) => {
      form.appendChild(createInput(value, name));
    });

    document.body.appendChild(form);

    form.submit();
  },
  /**
   *
   * @param {*} features
   * @param boolean isSearchPage
   * @returns string
   */
  manageFeatures: (features, isSearchPage = false) => {
    if (!features) return;

    let featuresLabel = '';

    [...Object.keys(features).splice(0, 2)].forEach((key) => {
      if (isSearchPage) {
        featuresLabel += `<p>${features[key]}</p>`;
      } else {
        if (featuresLabel.length > 0) {
          featuresLabel += `, ${features[key]}`;
        } else {
          featuresLabel += features[key];
        }
      }
    });

    return featuresLabel;
  },
  /**
   *
   * @param {*} images
   * @param {Element} parentElement
   * @returns string
   */
  manageImage: (image, title, parentElement) => {
    if (image) {
      const imageElement = parentElement.querySelector('img');

      if (imageElement) {
        imageElement.src = image.image_url ?? window.PLACEHOLDER_IMAGE;
        imageElement.alt = title ?? '';
      }
    }
  },
  /**
   *
   * @param string key
   * @param string value
   * @param {Element} element
   * @param string color
   * @param boolean isSearchPage
   * @returns
   */
  manageValues: (
    key,
    id,
    type,
    value,
    element,
    color,
    isSearchPage = false
  ) => {
    if (!element) return;

    if (isSearchPage && key === '%title%') {
      element.innerHTML = element.innerHTML.replaceAll(key, value);
    } else {
      element.innerHTML = element.innerHTML.replace(key, value);
    }

    if (element.dataset.id === '') {
      element.dataset.id = id;
    }

    if (element.dataset.trialsType === '') {
      element.dataset.trialsType = type;
    }

    if (!color) return;

    if (isSearchPage) {
      const label = element.querySelector(color.key);

      if (!label) return;

      label.classList.add(color.value);
    } else {
      element.innerHTML = element.innerHTML.replace(color.key, color.value);
    }
  },
  /**
   *
   * @param {[{title: string, features: [], image: [], url: string}]} items
   * @param {Element} template
   * @param {Element} container
   * @param {'semences'| 'contents' | 'tryouts'} type
   * @returns
   */
  hydrateDropdown: (items, template, container, type) => {
    if (items) {
      const onlyFirstFourth = [...items.slice(0, 4)];

      onlyFirstFourth.forEach((item) => {
        if (!item) return;

        const {
          id,
          title,
          type: trialType,
          features,
          image,
          url,
          categories,
          category,
          label,
          color
        } = item;

        const newElement = template.cloneNode(true);

        let labelValue = {
          semences:
            categories && categories[0] ? categories[0].i18ns.fr_FR.title : '',
          contents: label,
          tryouts: category ?? ''
        };

        let newTitle = title;

        if (item.type === 'testimony') {
          newTitle = `Témoignage d'agriculteurs sur la semence ${title}`;
        }

        const values = [
          {
            key: '%label%',
            value: labelValue[type] ?? '',
            color: {
              key: '%labelColor%',
              value: color ? color : 'text-corn-silage'
            }
          },
          { key: '%title%', value: newTitle ?? '' }
        ];

        if (type === SearchAction.ELEMENT_TYPE.SEMENCES && features) {
          values.push({
            key: '%features%',
            value: SearchActionService.manageFeatures(features)
          });
        }

        values.forEach(({ key, value, color }) => {
          SearchActionService.manageValues(
            key,
            id,
            trialType,
            value,
            newElement,
            color,
            false
          );
        });

        newElement.href = url ?? '/';

        SearchActionService.manageImage(image, newTitle, newElement);

        newElement.classList.replace(
          SearchAction.CLASSES.CLASS_TO_HIDDEN,
          SearchAction.CLASSES.FLEX
        );

        if (type === SearchAction.ELEMENT_TYPE.TRYOUTS) {
          newElement.addEventListener(
            'click',
            SearchActionService.actionSearchSubmit
          );
        }

        container.appendChild(newElement);
      });

      if (items.length >= SearchAction.SHOW_LIMIT) {
        toggleBtnAll(type);
      } else {
        toggleBtnAll(type, 'add');
      }
    }
  },
  /**
   *
   * @param {[{title: string, features: [], image: [], url: string}]} items
   * @param {Element} template
   * @param {Element} container
   * @param {'semences'| 'contents' | 'tryouts'} type
   * @returns
   */
  hydrateSearch: (items, template, container, type) => {
    if (!items) return;

    items.forEach((object) => {
      if (!object) return;

      const { title, features, image, url, categories, label, color } = object;

      const newElement = template.cloneNode(true);

      let labelValue = {
        semences: {
          value:
            categories && categories[0] ? categories[0].i18ns.fr_FR.title : '',
          colorClass: '.js-singleProductLabel'
        },
        contents: { value: label, colorClass: '.js-cardLabel' },
        tryouts: { value: label }
      };

      const values = [
        {
          key: '%label%',
          value: labelValue[type].value,
          color: {
            key: labelValue[type].colorClass,
            value: color ? color : 'text-corn-silage'
          }
        },
        { key: '%title%', value: title ?? '' }
      ];

      if (type === SearchAction.ELEMENT_TYPE.SEMENCES) {
        values.push({
          key: '%features%',
          value: SearchActionService.manageFeatures(features, true)
        });
      }

      values.forEach(({ key, value, color }) => {
        SearchActionService.manageValues(
          key,
          null,
          null,
          value,
          newElement,
          color,
          true
        );
      });

      newElement.href = url ?? '/';

      SearchActionService.manageImage(image, title ?? '', newElement);

      container.appendChild(newElement);
    });

    container.removeChild(container.querySelector('div.hidden'));
  },
  manageLoader: () => {
    const loader = document.querySelector(SearchAction.CLASSES.LOADER);
    const resultContainer = document.querySelector(
      SearchAction.CLASSES.SEARCH_RESULT
    );
    const searchPage = document.querySelector('.js-search');

    if (loader) {
      loader.classList.toggle(SearchAction.CLASSES.CLASS_TO_HIDDEN);
    }

    if (searchPage) {
      searchPage.classList.remove(SearchAction.CLASSES.CLASS_TO_HIDDEN);
    }

    if (resultContainer) {
      resultContainer.classList.remove(SearchAction.CLASSES.CLASS_TO_HIDDEN);
    }
  },
  manageParams: (products, contents, trials, value) => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('q') && !window.IS_POST) {
      const tabs = document.querySelectorAll('.js-searchTab');

      if (!tabs) return;

      const totalResults = products.length + contents.length + trials.length;

      // to make it work with tabs
      window.SEMENCES_COUNT = products.length;
      window.CONTENTS_COUNT = contents.length;
      window.TRYOUTS_COUNT = trials.length;

      let titleAmount = document.querySelector('.js-searchHeaderTitle');

      const valueSearch = document.querySelector('.js-searchValue');

      titleAmount.innerHTML = titleAmount.innerHTML.replace(
        '%amount%',
        totalResults
      );

      valueSearch.innerHTML = `"${value}"`;

      tabs.forEach((tab) => {
        if (tab.id === SearchAction.ELEMENT_TYPE.SEMENCES) {
          if (products.length === 0) {
            tab.classList.add('hidden');
          }

          tab.querySelector('span').innerText = products.length;
          tab.classList.toggle('active');
        } else if (tab.id === SearchAction.ELEMENT_TYPE.CONTENTS) {
          if (contents.length === 0) {
            tab.classList.add('hidden');
          }

          tab.querySelector('span').innerText = contents.length;
        } else if (tab.id === SearchAction.ELEMENT_TYPE.TRYOUTS) {
          if (trials.length === 0) {
            tab.classList.add('hidden');
          }

          tab.querySelector('span').innerText = trials.length;
        }
      });

      const visibleTabs = document.querySelectorAll(
        '.js-searchTab:not(.hidden)'
      );

      const firstTabs = document.querySelector(`#${visibleTabs[0].id}`);

      if (!firstTabs) return;

      firstTabs.classList.toggle('active');

      const content = document.querySelector(
        `.js-searchResultsItems[data-type="${firstTabs.id}"]`
      );

      if (!content) return;

      content.classList.toggle('active');
    }
  }
};

export default SearchActionService;
