import _ from 'lodash-es';

let scrollState = window.scrollY;
const header = document.querySelector('#Header');
const DEBOUNCED_DELAY = 0;

const ManageHeader = (
  ELEMENT_CLASS,
  NOT_STICKY_CLASS,
  ACTIVE_CLASS,
  callback
) => {
  return _.debounce((event) => {
    if (window.scrollY <= 0) {
      header.classList.remove(NOT_STICKY_CLASS);

      if (callback) {
        callback();
      }

      scrollState = window.scrollY;

      return;
    }

    const buttons = document.querySelector(ELEMENT_CLASS);

    if (buttons.classList.contains(ACTIVE_CLASS)) {
      header.classList.toggle(NOT_STICKY_CLASS, window.scrollY > scrollState);
    } else {
      header.classList.remove(NOT_STICKY_CLASS);
    }

    if (callback) {
      callback();
    }

    scrollState = window.scrollY;
  }, DEBOUNCED_DELAY);
};

export default ManageHeader;
