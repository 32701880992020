import SearchActionService from './SearchActionService';
import WindowState from '../../assets/js/utils/windowState';
import _ from 'lodash-es';

const {
  MIN_SEARCH_CHARACTER,
  CLASSES,
  EVENT_TYPE,
  DROPDOWN_STATUS,
  ELEMENT_TYPE
} = SearchActionService.SearchAction;

const {
  CLASS_TO_HIDDEN,
  DROPDOWN_CLASS,
  ACTIVE_CLASS,
  SEMENCES_CONTAINER,
  SEMENCES_TEMPLATE,
  CONTENTS_CONTAINER,
  CONTENTS_TEMPLATE,
  TRYOUTS_CONTAINER,
  TRYOUTS_TEMPLATE,
  SEARCH_INPUT,
  NO_CONTENT
} = CLASSES;

/**
 *
 *
 * @param { 'add' | 'remove' | undefined } status
 */
const toggleDropdown = (status) => {
  const dropdown = document.querySelector(DROPDOWN_CLASS);

  if (!status) {
    dropdown.classList.toggle(ACTIVE_CLASS);
  }

  if (status === 'add') {
    dropdown.classList.add(ACTIVE_CLASS);
  } else {
    dropdown.classList.remove(ACTIVE_CLASS);
  }
};

const hydrateElement = ({ products, contents, trials }) => {
  const elements = [
    {
      type: ELEMENT_TYPE.SEMENCES,
      object: products,
      container: SEMENCES_CONTAINER,
      template: SEMENCES_TEMPLATE
    },
    {
      type: ELEMENT_TYPE.CONTENTS,
      object: contents,
      container: CONTENTS_CONTAINER,
      template: CONTENTS_TEMPLATE
    },
    {
      type: ELEMENT_TYPE.TRYOUTS,
      object: trials,
      container: TRYOUTS_CONTAINER,
      template: TRYOUTS_TEMPLATE
    }
  ];

  elements.forEach(({ type, object, container, template }) => {
    const templateElement = document.querySelector(
      `${template}.${CLASS_TO_HIDDEN}`
    );

    const containerElement = document.querySelector(container);

    if (!templateElement || !containerElement) return;

    const noContent = containerElement.querySelector(NO_CONTENT);

    if (noContent && !noContent.classList.contains(CLASS_TO_HIDDEN)) {
      noContent.classList.add(CLASS_TO_HIDDEN);
    }

    if (
      (!object && noContent) ||
      (object && object.length === 0 && noContent)
    ) {
      noContent.classList.remove(CLASS_TO_HIDDEN);
    }

    const containerHasChild = containerElement.querySelectorAll(
      `${template}:not(.${CLASS_TO_HIDDEN})`
    );

    if (containerHasChild.length > 0) {
      containerHasChild.forEach((child) => containerElement.removeChild(child));
    }

    SearchActionService.hydrateDropdown(
      object,
      templateElement,
      containerElement,
      type
    );

    document.querySelectorAll('.js-searchTryouts').forEach((moreContent) => {
      if (moreContent) {
        moreContent.addEventListener(
          'click',
          SearchActionService.actionSearchSubmit
        );
      }
    });

    templateElement.classList.add('hidden');
  });
};

const manageResponse = (response) => {
  if (!response) return;

  // if response show dropdown !
  if (response.products && response.contents) {
    // manage loader
    hydrateElement(response);
    toggleDropdown(DROPDOWN_STATUS.OPEN);
  } else {
    toggleDropdown(DROPDOWN_STATUS.CLOSE);
  }
};

const initSearchAction = () => {
  const inputs = document.querySelectorAll(SEARCH_INPUT);

  if (!inputs) return;

  // on mobile
  const buttonSearch = document.querySelector('.HeaderSearchAction-search');

  if (buttonSearch) {
    buttonSearch.addEventListener('click', ({ currentTarget }) => {
      if (WindowState.BELOW_XL_SCREEN.get()) {
        const overlay = document.querySelector('.js-searchOverlay');

        if (!overlay) return;

        if (
          document
            .querySelector(`${SEARCH_INPUT}[data-window-state="mobile"]`)
            .value.replace(/\s/g, '').length > MIN_SEARCH_CHARACTER
        ) {
          toggleDropdown(DROPDOWN_STATUS.OPEN);
        }

        overlay.classList.toggle(ACTIVE_CLASS);
      }
    });
  }

  const submitSearch = document.querySelector('.js-searchSubmit');

  if (submitSearch) {
    submitSearch.addEventListener('click', async () => {
      if (!WindowState.BELOW_XL_SCREEN.get()) {
        const inputs = document.querySelectorAll(SEARCH_INPUT);

        if (!inputs) return;

        let value = '';

        inputs.forEach((input) => {
          if (!input.value) return;

          value = input.value;
        });

        if (!value || value.replace(/\s/g, '').length < MIN_SEARCH_CHARACTER)
          return;

        const data = await SearchActionService.search(value);

        SearchActionService.searchSubmit(value, data);
      }
    });
  }

  const searchOverlayClose = document.querySelector('.js-searchOverlayClose');

  if (searchOverlayClose) {
    searchOverlayClose.addEventListener(
      EVENT_TYPE.CLICK,
      ({ currentTarget }) => {
        if (WindowState.BELOW_XL_SCREEN.get()) {
          const overlay = document.querySelector('.js-searchOverlay');

          if (!overlay) return;

          toggleDropdown(DROPDOWN_STATUS.CLOSE);
          overlay.classList.toggle(ACTIVE_CLASS);
        }
      }
    );
  }

  const debounceDropdown = _.debounce(async (value) => {
    if (value.replace(/\s/g, '').length < MIN_SEARCH_CHARACTER) {
      toggleDropdown();
      return;
    }

    manageResponse(await SearchActionService.search(value));
  }, 250);

  inputs.forEach((input) => {
    input.addEventListener(EVENT_TYPE.CLICK, (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    input.addEventListener(EVENT_TYPE.KEY_UP, async (event) => {
      const { currentTarget, key } = event;
      const { value } = currentTarget;

      if (['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(key)) {
        return;
      }

      if (
        key === EVENT_TYPE.ENTER &&
        value.replace(/\s/g, '').length > MIN_SEARCH_CHARACTER
      ) {
        // When enter make a form and submit it, and redirect to /search
        const data = await SearchActionService.search(value);

        SearchActionService.searchSubmit(value, data);
        return;
      }

      debounceDropdown(value);
    });
  });

  window.addEventListener('resize', () => {
    const overlay = document.querySelector('.js-searchOverlay');

    if (!overlay) return;

    const overlayInput = overlay.querySelector(
      `${SEARCH_INPUT}[data-window-state="mobile"]`
    );
    const desktopInput = document.querySelector(
      `${SEARCH_INPUT}[data-window-state="desktop"]`
    );

    const dropdownOpen = document.querySelector('.js-resultsDropdown.active');

    if (overlay) {
      if (!WindowState.BELOW_XL_SCREEN.get()) {
        overlay.classList.remove(ACTIVE_CLASS);
        desktopInput.value = overlayInput.value;
      } else if (dropdownOpen) {
        overlay.classList.add(ACTIVE_CLASS);
        overlayInput.value = desktopInput.value;
      }
    }
  });

  document.querySelectorAll('.js-moreSearch').forEach((moreContent) => {
    moreContent.addEventListener(
      'click',
      SearchActionService.actionSearchSubmit
    );
  });
};

const hydrateSearchPage = async (value = '') => {
  if (!value) return;

  try {
    const { products, contents, trials } = await SearchActionService.search(
      value
    );

    if (!products || !contents || !trials) return;

    SearchActionService.manageParams(products, contents, trials, value);

    const elements = [
      {
        type: ELEMENT_TYPE.SEMENCES,
        objects: products,
        container: `.js-searchResultsItems[data-type="${ELEMENT_TYPE.SEMENCES}"]`,
        template: '.js-templateContainer>.SingleProduct'
      },
      {
        type: ELEMENT_TYPE.CONTENTS,
        objects: contents,
        container: `.js-searchResultsItems[data-type="${ELEMENT_TYPE.CONTENTS}"]`,
        template: '.js-templateContainer>a'
      },
      {
        type: ELEMENT_TYPE.TRYOUTS,
        objects: trials,
        container: `.js-searchResultsItems[data-type="${ELEMENT_TYPE.CONTENTS}"]`,
        template: '.js-templateContainer>a'
      }
    ];

    elements.forEach(({ type, container, template, objects }) => {
      const elementContainer = document.querySelector(container);

      if (!elementContainer) return;

      const elementTemplate = elementContainer.querySelector(template);

      if (!elementTemplate) return;

      SearchActionService.hydrateSearch(
        objects,
        elementTemplate,
        elementContainer,
        type
      );
    });

    SearchActionService.manageLoader();
  } catch (error) {
    console.error('error: ', error);
  }
};

const HeaderSearchAction = () => {
  initSearchAction();

  if (window.SEARCHED_VALUE) {
    hydrateSearchPage(window.SEARCHED_VALUE);
  }
};

export default HeaderSearchAction;
