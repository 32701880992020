import axios from 'axios';
import { verifyRecaptcha } from '@js/utils/Recaptcha';
import { createCookie, getKeyEmailFormData } from '@js/utils/SessionCookies';

export const Overlay = {
  salesmanId: null,
  buttonsOpen: () => document.querySelectorAll('.js-openOverlayContact'),
  buttonsClose: () => document.querySelectorAll('.js-closeOverlayContact'),
  form: () => document.querySelector('.js-contactForm'),
  content: () => document.querySelector('.js-contactOverlayFormContainer'),
  successContent: () =>
    document.querySelectorAll('.js-OverlayContactFormSuccess'),
  /**
   * Init overlay
   * @returns void
   */
  init: () => {
    if (!Overlay.buttonsOpen()) return;

    Overlay.buttonsOpen().forEach((button) => {
      button.addEventListener('click', Overlay.toggle);
    });

    if (!Overlay.buttonsClose()) return;

    Overlay.buttonsClose().forEach((close) =>
      close.addEventListener('click', Overlay.toggle)
    );
    Overlay.form()?.addEventListener('submit', Overlay.submit);
  },
  /**
   * ToggleOverlay
   * @param {Event} event
   * @returns void
   */
  toggle: (event) => {
    const askingAboutHidden = document.querySelector(
      '.js-contactForm > .js-askingAbout.hidden'
    );

    if (askingAboutHidden) {
      askingAboutHidden.classList.remove('hidden');

      const option = askingAboutHidden.querySelector(`option:checked`);

      if (!option) return;

      option.selected = false;
    }

    if (event.currentTarget.dataset.salesmanId) {
      const inputSalesman = document.querySelector('.js-salesmanInput');

      if (inputSalesman) {
        inputSalesman.value = event.currentTarget.dataset.salesmanId;
      }

      const askingAbout = document.querySelector(
        '.js-contactForm > .js-askingAbout'
      );

      if (askingAbout.classList.contains('hidden')) {
        askingAbout.classList.remove('hidden');
      }

      if (askingAbout && event.currentTarget.dataset.salesmanType) {
        askingAbout.classList.add('hidden');

        const option = askingAbout.querySelector(
          `option[value=${event.currentTarget.dataset.salesmanType}]`
        );

        if (!option) return;

        option.selected = true;
      }

      Overlay.salesmanId = event.currentTarget.dataset.salesmanId;
    }

    if (
      event.currentTarget.dataset.state &&
      event.currentTarget.dataset.state === 'close'
    ) {
      Overlay.success(true);
    }

    const overlay = document.querySelector('.js-contactFormContainer');

    if (!overlay) return;

    overlay.classList.toggle('open');
    document.body.classList.toggle('hasOverlayOpen');

    window.dataLayer.push({
      pageTheme: 'CONTACT'
    });
  },
  /**
   * submit overlayForm.
   * @param {SubmitEvent} event
   */
  submit: async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (!form) return;

    try {
      await verifyRecaptcha(form);
    } catch (error) {
      const alert = document.querySelector('.js-alertContact');

      if (!alert) return;

      alert.classList.remove('hidden');
      alert.innerHTML = error.message;

      return;
    }

    const formData = form ? new FormData(form) : new FormData();

    const retrivedKey = getKeyEmailFormData(formData);

    if (retrivedKey) {
      createCookie(formData.get(retrivedKey), '_realtimeasset');
    }

    try {
      const response = await axios.post('/lgseeds/contact', formData);

      if (response.status !== 200) return;

      window.dataLayer.push({
        event: 'CONTACT',
        action: 'SUBMIT'
      });

      Overlay.success();
    } catch (error) {
      const { errors, error: errorMessage } = error.response.data;

      if (errors) {
        const errorsList = [
          { value: errors.availability_day, type: 'availability_day' },
          { value: errors.availability_hours, type: 'availability_hours' },
          { value: errors.firstname, type: 'firstname' },
          { value: errors.lastname, type: 'lastname' },
          { value: errors.email, type: 'email' },
          { value: errors.phone, type: 'phone' },
          { value: errors.profile, type: 'profile' },
          { value: errors.asking_about, type: 'asking_about' },
          { value: errors.update_data, type: 'update_data' }
        ];

        errorsList.forEach((error) => Overlay.error(error.value, error.type));
      }

      if (errorMessage) {
        const alert = document.querySelector('.js-alertContact');

        if (!alert) return;

        alert.classList.remove('hidden');
        alert.innerHTML = errorMessage;
      }
    }
  },
  /**
   * Toogle success
   * @param {boolean} reset
   * @returns void
   */
  success: (reset = false) => {
    if (!Overlay.content() || !Overlay.successContent()) return;

    if (reset) {
      Overlay.content().classList.add('active');
      Overlay.successContent().forEach((success) => {
        success.classList.remove('active');
      });
      return;
    }

    Overlay.content().classList.toggle('active');
    Overlay.successContent().forEach((success) => {
      success.classList.toggle('active');
    });
  },
  error: (value, type) => {
    if (value) {
      const labelError = document.querySelector(
        `#overlay-contact-error-${type}`
      );

      labelError.innerHTML = value;
    }
  }
};

const OverlayContact = () => {
  Overlay.init();
};

export default OverlayContact;
