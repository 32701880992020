import '@splidejs/splide/dist/css/splide.min.css';

import Splide from '@splidejs/splide';

const CONFIG_SPLIDE = {
  loop: true,
  autoWidth: true,
  autoHeight: true,
  autoPlay: true,
  rewind: true,
  arrows: false,
  pagination: false,
  perPage: 2,
  focus: 'center',
  drag: false,
  breakpoints: {
    1280: {
      perPage: 1,
      pagination: false,
      drag: true
    }
  }
};

const AccountHeader = () => {
  const accountHeader = document.querySelector(
    '.AccountHeader-subContent-advisers'
  );
  if (accountHeader) {
    new Splide(`.AccountHeader-subContent-advisers`, CONFIG_SPLIDE).mount();
  }
};

export default AccountHeader;
