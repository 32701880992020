const ManageDataLayer = () => {
  const registerForm = document.querySelector('#form-register');

  if (registerForm) {
    registerForm.addEventListener('submit', (event) => {
      window.dataLayer.push({
        event: 'REGISTER',
        action: 'SUBMIT'
      });
    });
  }

  const loginForm = document.querySelector('#loginform-login');

  if (loginForm) {
    loginForm.addEventListener('submit', (event) => {
      window.dataLayer.push({
        event: 'LOGIN',
        action: 'SUBMIT'
      });
    });
  }
};

export default ManageDataLayer;
