import 'mmenu-light/dist/mmenu-light.css';

import MmenuLight from 'mmenu-light';

export default async function Header() {
  if (!document.querySelector('#Header-menu')) return;

  const menu = new MmenuLight(
    document.querySelector('#Header-menu'),
    '(max-width: 1023px)'
  );

  menu.navigation({
    navbar: false
  });
  const drawer = menu.offcanvas();

  let isOpen = false;

  document
    .querySelector("a[href='#Header-menu']")
    .addEventListener('click', (event) => {
      event.preventDefault();

      event.currentTarget.classList.toggle('is-closed', isOpen);

      if (isOpen) {
        drawer.close();
      } else {
        drawer.open();
      }
      isOpen = !isOpen;
    });

    if(!document.body.classList.contains('no-js')) {
      addAgriLandingToMenu();
    }
}

function addAgriLandingToMenu() {
  const agriLanding = document.getElementById('js-agri-landing')?.innerHTML;
  const subNavs = document.querySelectorAll('.Header-menu-list .subNav');
  const subCats = document.querySelectorAll('.js-Categories');
  const events = ['mouseenter', "mouseout"];

  if(!agriLanding) return null;

  subNavs.forEach(sub => {
    const agriLandingInside = document.createElement("div");
    agriLandingInside.classList.add('AgriLandingWrapper')
    agriLandingInside.innerHTML = agriLanding;
    sub.appendChild(agriLandingInside);
  })

  subCats.forEach(cat => {
    events.forEach((e) => {
      cat.addEventListener(e, () => cat.closest('.subNav')?.querySelector('.AgriLandingWrapper')?.classList.toggle('hidden'))
    });
  })
}
