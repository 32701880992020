import ManageHeader from '@utils/manageHeader';
import observeStickyElement from '@utils/manageStickyElement';

const manageSticky = () => {
  const stickyElement = document.querySelector('.js-stickyNav');

  if (stickyElement) {
    const manageHeader = ManageHeader('.js-stickyNav', 'notSticky', 'active');

    document.addEventListener('scroll', manageHeader);
    observeStickyElement(stickyElement, 'active');
  }
};

const HeaderPage = () => {
  manageSticky();
};

export default HeaderPage;
