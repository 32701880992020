import '@splidejs/splide/dist/css/splide.min.css';

import Splide from '@splidejs/splide';

const isOverflown = (element, length) => {
  if (!element) return;

  const width = element.getBoundingClientRect().width * length;
  return width > window.innerWidth;
};

const ProductSlides = () => {
  const productList = document.querySelector('.js-productList');

  if (!productList) return;

  const { lastElementChild } = productList;
  const length = productList.children.length;

  const value = lastElementChild?.getBoundingClientRect().width;

  const CONFIG_SPLIDE = {
    arrows: false,
    pagination: isOverflown(lastElementChild, length),
    drag: isOverflown(lastElementChild, length),
    perPage: 4,
    swipeDistanceThreshold: value,
    autoHeight: true,
    autoWidth: true,
    breakpoints: {
      1900: {
        perPage: 4
      },
      768: {
        perPage: 2,
        pagination: true,
        drag: true
      }
    }
  };

  if (document.querySelector(`#ProductSlide`)) {
    new Splide(`#ProductSlide`, CONFIG_SPLIDE).mount();
  }
};

export default ProductSlides;
