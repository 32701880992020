/**
 * Création d'un cookie
 * @param {string} value
 * @param {string} key
 */
export function createCookie(value, key) {
  if (getCookie(key) === value) return;

  document.cookie = `${key}=${value}`;
}

/**
 * getKeyEmailFormData
 * @param {FormData} formData
 * @returns {string}
 */
export function getKeyEmailFormData(formData) {
  let nameInput = null;

  for (var pair of formData.entries()) {
    if (pair[0].includes('email')) {
      nameInput = pair[0];
    }
  }
  return nameInput;
}

export function initCookieAtStartWhenLogged() {
  if (window?.CUSTOMER_EMAIL) {
    const email = getCookie('_realtimeasset');

    if (email !== window.CUSTOMER_EMAIL) {
      createCookie(window.CUSTOMER_EMAIL, '_realtimeasset');
    }
  }
}

/**
 * Récupérer un cookie
 * @param {string} key
 * @returns {string}
 */
export function getCookie(key) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function listenToEmailValueSubmitting() {
  document.addEventListener('submit', (event) => {
    const { target } = event;
    const formData = new FormData(target);

    const retrivedKey = getKeyEmailFormData(formData);

    // s'il est actuellement connecté on lui met son mail
    // vérifier s'il est connecté mettre son mail.
    if (retrivedKey) {
      createCookie(formData.get(retrivedKey), '_realtimeasset');
    }
  });
}
