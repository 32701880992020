import axios from 'axios';

const TheliaAi = () => {
  const taiElements = document.querySelectorAll('.js-tai');

  taiElements.forEach((taiEl) => {
    taiEl.addEventListener('click', (event) => {
      const el = event.currentTarget;
      if (!el) {
        // console.log("TAI - no element");
        return;
      }

      const json = el.getAttribute('data-tai');
      if (!json) {
        // console.log("TAI - no json");
        return;
      }

      // console.log("TAI send data", json);
      axios.post('/tai/ping', json, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    });
  });
};

export default TheliaAi;
