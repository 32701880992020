import '@splidejs/splide/dist/css/splide.min.css';

import Splide from '@splidejs/splide';
import WindowState from '../../assets/js/utils/windowState';

const isOverflown = (element, length) => {
  if (!element) return;

  const width = element.getBoundingClientRect().width * length;

  const paddingX = 208;

  const parentWidth =
    element.parentElement.parentElement.getBoundingClientRect().width -
    paddingX;

  return width > parentWidth;
};

const Tabs = () => {
  const tabContent = document.querySelector('#TabContent');
  const tabs = document.querySelectorAll('.js-tabs');

  const { tabContents } = window;

  tabs.forEach((tab) => {
    tab.addEventListener('click', ({ currentTarget }) => {
      const id = parseInt(currentTarget.dataset.id, 10);
      tabContent.innerHTML = tabContents[id][1];
    });
  });

  const splidesTabs = document.querySelectorAll('.tabs-content');

  /**
   * To detect if the element is outside
   *
   * @param {Element} element
   * @return {*}
   */
  const verifyListOverflow = (element) => {
    const arrows = document.querySelector(`.splide__arrows`);

    if (!element || !arrows) return;

    const list = element.querySelector('.splide__list');

    if (!list) return;

    const needArrow =
      list.getBoundingClientRect().right + 60 >
      (window.innerWidth || document.documentElement.clientWidth);

    if (WindowState.MOBILE_STATE.get() || needArrow) {
      list.classList.add('pl-4');
      arrows.classList.remove('invisible');
    } else if (!WindowState.TABLET_STATE.get() || !needArrow) {
      list.classList.remove('pl-4');
      arrows.classList.add('invisible');
    }
  };

  if (splidesTabs.length > 0) {
    splidesTabs.forEach((splideTabs) => {
      const list = splideTabs.querySelector('.splide__list');
      const arrows = splideTabs.querySelector('.splide__arrows');

      if (arrows.classList.contains('hidden')) {
        arrows.classList.remove('hidden');
      }

      const { lastElementChild } = list;
      const { length } = list.children;

      if (!isOverflown(lastElementChild, length)) {
        const track = splideTabs.querySelector('.tabs-list');

        track.classList.add('noArrow');
        arrows.classList.add('hidden');
      }

      const CONFIG_SPLIDE = {
        loop: true,
        arrows: true,
        drag: true,
        autoWidth: true,
        autoHeight: true,
        pagination: false,
        padding: {
          left: 0,
          right: '10rem'
        }
      };

      if (splideTabs.id === 'tabsInnovation') {
        verifyListOverflow(splideTabs);

        window.addEventListener('resize', (event) =>
          verifyListOverflow(splideTabs)
        );

        CONFIG_SPLIDE.breakpoints = {
          2560: {
            drag: false,
            padding: {
              left: 0,
              right: 0
            }
          },
          1024: {
            drag: true
          }
        };
      }

      new Splide(`#${splideTabs.id}`, CONFIG_SPLIDE).mount();
    });

    window.addEventListener('resize', () => {
      const splides = document.querySelectorAll('.tabs-content');

      splides.forEach((tab) => {
        const list = tab.querySelector('.splide__list');
        const { lastElementChild } = list;
        const { length } = list.children;

        const arrows = tab.querySelector('.splide__arrows');
        const track = tab.querySelector('.tabs-list');

        if (isOverflown(lastElementChild, length)) {
          track.classList.remove('noArrow');
          arrows.classList.remove('hidden');
        } else {
          track.classList.add('noArrow');
          arrows.classList.add('hidden');
        }
      });
    });
  }
};

export default Tabs;
