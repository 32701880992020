const TopBar = () => {
  const bar = document.getElementById('TopBar');
  const btn = document.getElementById('TopBarBtn');

  if(!bar || !btn) return null;

  btn.addEventListener('click', (e) => {
    document.cookie = "_topbar=1";
    bar.classList.add('hidden');
    document.body?.classList.remove('had-topbar');
  })
}

export default TopBar;
