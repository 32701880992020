import FIELDS from './fields';
import _ from 'lodash-es';
import axios from 'axios';

const CallToActionService = {
  displayedField: [],
  getFields: async (callToActionId, email = '') => {
    try {
      const response = await axios.get(
        `/call_to_action/fields/${callToActionId}${email}`
      );

      return response.data.fields;
    } catch (error) {
      console.error('error: ', error);
    }
  },
  postCTA: async (formData, id) => {
    try {
      const response = await axios.post(`/call_to_action/${id}`, formData);

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  },
  getRessource: (
    callToActionId,
    email = '',
    isDownload = false,
    title = ''
  ) => {
    try {
      if (isDownload) {
        window.dataLayer.push({
          event: 'CTA_DOWNLOAD',
          ctaName: title,
          action: 'SUBMIT'
        });

        return;
      }

      window.dataLayer.push({
        event: 'CTA_RESOURCE_ACCESS',
        action: 'SUBMIT'
      });

      const { IS_TARGET_BLANK } = window;

      const uri = `/call_to_action/${callToActionId}${email ? email : ''}`;

      if (IS_TARGET_BLANK) {
        window.open(uri, '_blank');
      } else {
        window.location.href = uri;
      }
    } catch (error) {
      console.error('error: ', error);
    }
  },
  getMagazine: async (callToActionId, email) => {
    try {
      if (!callToActionId || !email) return;

      window.dataLayer.push({
        event: 'CTA_SUBSCRIPTION',
        action: 'GET_MAGAZINE'
      });

      const response = await axios.get(
        `/call_to_action/magazine/${callToActionId}${email}`
      );

      return response.data.type;
    } catch (error) {
      console.error('error: ', error);
    }
  },
  /**
   * Current fix for override required field.
   *
   */
  disableFormRequired: (form) => {
    const requiredAtFalse = (value) => {
      if (value) {
        value.required = false;
      }
    };

    if (!form) return;

    requiredAtFalse(form.querySelector(`.${FIELDS.email.class}>div>input`));
  },
  /**
   * Verify if the email is valid
   * @param {string} email
   * @returns boolean
   */
  verifEmail: (email) => {
    const regex =
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    return regex.test(email);
  },
  /**
   * Init form register to manage it with two steps
   * @param {Element} form
   * @returns
   */
  initFormRegister: (form) => {
    const parentSectionCTA = form.closest('section.ServiceModule');
    const firstStep = parentSectionCTA.querySelector(
      '.js-registerCtaFormFirstStep'
    );

    if (!firstStep || !form) return;

    CallToActionService.manageFormRegiser(parentSectionCTA);

    const nextButton = firstStep.querySelector('.js-registerCtaFormNextStep');
    const previousButton = parentSectionCTA.querySelector(
      '.js-registerCtaFormPreviousStep'
    );

    if (!nextButton || !previousButton) return;

    nextButton.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      CallToActionService.manageFormRegiser(false);
    });

    previousButton.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();

      CallToActionService.manageFormRegiser(true);
    });

    const debounceKeyUpForm = _.debounce(({ target }) => {
      const firstStepInput = firstStep.querySelectorAll('input');
      let isAllFilled = true;

      firstStepInput.forEach((input) => {
        if (!input.value) {
          isAllFilled = false;
        }
      });

      nextButton.disabled = !isAllFilled;
    }, 200);

    form.addEventListener('keyup', debounceKeyUpForm);
  },
  /**
   * Manage display of register
   * @param {*} parentSectionCTA
   * @param {boolean} isFirstStep
   * @returns
   */
  manageFormRegiser: (parentSectionCTA, isFirstStep = true) => {
    const firstStep = parentSectionCTA.querySelector(
      '.js-registerCtaFormFirstStep'
    );
    const secondStep = parentSectionCTA.querySelector(
      '.js-registerCtaFormSecondStep'
    );
    const nextButton = firstStep.querySelector('.js-registerCtaFormNextStep');
    const submit = parentSectionCTA.querySelector('.js-ctaSubmit');

    const inputs = firstStep.querySelectorAll('input');
    let allInputsIsFilled = true;

    inputs.forEach((input) => {
      if (!input.value) {
        allInputsIsFilled = false;
      }
    });

    if (!isFirstStep && !allInputsIsFilled) return;

    secondStep.classList.toggle('hidden', isFirstStep);
    submit.classList.toggle('hidden', isFirstStep);
    firstStep.classList.toggle('hidden', !isFirstStep);
    nextButton.classList.toggle('hidden', !isFirstStep);
  },
  manageDefaultCta: async (
    form,
    parentSectionForm,
    buttonCta,
    isConnected,
    callToActionId,
    manageForm
  ) => {
    const emailField = parentSectionForm.querySelector(
      `.${FIELDS.email.class}`
    );
    const btnValidateEmail = parentSectionForm.querySelector(
      `.${FIELDS.email_submit.class}`
    );
    const content = parentSectionForm.querySelector(`.${FIELDS.content.class}`);
    const formContainer = parentSectionForm.querySelector(
      `.${FIELDS.form_container.class}`
    );

    if (!emailField || !btnValidateEmail || !content || !buttonCta || !form)
      return;

    const connectedOtherField = [content, formContainer];

    if (isConnected) {
      manageForm(
        callToActionId,
        await CallToActionService.getFields(callToActionId),
        form,
        parentSectionForm,
        connectedOtherField
      );

      return;
    }

    const notConnectedOtherField = [
      ...connectedOtherField,
      emailField,
      btnValidateEmail
    ];

    CallToActionService.toggleFormFields(notConnectedOtherField, 'hidden');

    btnValidateEmail.addEventListener('click', async (e) => {
      e.preventDefault();

      const { value = '' } =
        emailField.querySelector('.js-input input[type="email"]') || {};

      if (!CallToActionService.verifEmail(value)) {
        CallToActionService.toggleFormErrors(
          [
            {
              type: 'email',
              message: "Le champs email n'est pas remplis correctement."
            }
          ],
          "Tous les champs nécessaires n'ont pas été remplis correctement"
        );
        return;
      }

      if (value) {
        manageForm(
          callToActionId,
          await CallToActionService.getFields(
            callToActionId,
            value ? `?email=${encodeURIComponent(value)}` : ''
          ),
          form,
          parentSectionForm,
          [emailField]
        );
      }
    });
  },
  /**
   * Toggle form field
   *
   * @param {*} fieldListToToggle
   * @param {*} classToApply
   */
  toggleFormFields: (fieldListToToggle, classToApply) => {
    CallToActionService.displayedField = fieldListToToggle.slice();

    fieldListToToggle.forEach((field) => {
      if (field) {
        if (field.classList.contains(FIELDS.email.class)) {
          let classReplaced = 'hidden';
          let newClass = 'flex';

          if (!field.classList.contains(classReplaced)) {
            newClass = 'hidden';
            classReplaced = 'flex';
          }

          field.classList.replace(classReplaced, newClass);
          field.setAttribute('required', true);
        } else {
          field.classList.toggle(classToApply);
        }
      }
    });
  },

  /**
   * Toggle Form Success
   *
   * @param {*} form
   * @param {boolean} [isSuccess=true]
   */
  toggleFormSuccess: (form, isSuccess = true) => {
    let CLASS_TO_REPLACE = 'hidden';
    let CLASS_REPLACED_BY = 'flex';
    const parentSectionCTA = form.closest('section.ServiceModule');

    const successElement = parentSectionCTA.querySelector('.js-ctaFormSuccess');

    if (!isSuccess) {
      CLASS_TO_REPLACE = 'flex';
      CLASS_REPLACED_BY = 'hidden';
    }

    if (
      CallToActionService.displayedField &&
      CallToActionService.displayedField.length > 0
    ) {
      CallToActionService.displayedField.forEach((field) => {
        if (field && !field.classList.contains('hidden')) {
          field.classList.add('hidden');
        }
      });
    }
    const elementToVerify = [
      { class: `.${FIELDS.form_container.class}`, isAdding: false },
      { class: `.${FIELDS.content.class}:not(.hidden)`, isAdding: true }
    ];

    elementToVerify.forEach((element) => {
      const el = parentSectionCTA.querySelector(element.class);

      if (el) {
        element.isAdding
          ? el.classList.add('hidden')
          : el.classList.remove('hidden');
      }
    });

    const alert = parentSectionCTA.querySelector(
      '.js-ctaFormError:not(.hidden)'
    );

    if (alert) {
      alert.classList.add('hidden');
    }

    successElement.classList.replace(CLASS_TO_REPLACE, CLASS_REPLACED_BY);
  },
  /**
   * toggleErrors
   *
   * @param {*} errors
   * @param {*} message
   * @param {*} form
   * @return {*}
   */
  toggleFormErrors: (errors, message, form) => {
    const parentSectionCTA = form.closest('section.ServiceModule');
    const alert = parentSectionCTA.querySelector('.js-ctaFormError');

    if (!alert) return;

    alert.classList.remove('hidden');
    alert.innerHTML = message;

    Object.keys(errors).forEach((error) => {
      const field = parentSectionCTA.querySelector(
        `.${FIELDS[error].class}>div>input`
      );

      if (field) {
        field.classList.toggle('error');
      }

      const errorMessage = parentSectionCTA.querySelector(
        `.${FIELDS[error].class}>.error`
      );

      if (errorMessage) {
        errorMessage.innerHTML = errors[error];
      }
    });
  }
};

export default CallToActionService;
