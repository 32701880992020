const FIELDS = {
  email: {
    name: 'fn_field_email',
    class: 'js-callToActionFormEmail'
  },
  firstname: {
    name: 'fn_field_firstname',
    class: 'js-callToActionFormFirstname'
  },
  lastname: {
    name: 'fn_field_lastname',
    class: 'js-callToActionFormLastname'
  },
  zipcode: {
    name: 'fn_field_code_postal',
    class: 'js-callToActionFormZipcode'
  },
  phone: {
    name: 'fn_field_tel',
    class: 'js-callToActionFormPhone'
  },
  profile: {
    name: 'fn_field_profil',
    class: 'js-callToActionFormProfil'
  },
  typology: {
    name: 'fn_field_typologie',
    class: 'js-callToActionFormTypologie'
  },
  is_bio: {
    name: 'fn_field_culture_bio',
    class: 'js-callToActionFormBio'
  },
  info_sources: {
    name: 'fn_field_sources_infos',
    class: 'js-callToActionFormSourcesInfos'
  },
  other_info_source: {
    name: 'fn_field_sources_infos_autre',
    class: 'js-callToActionFormSourcesInfosOther'
  },
  follow_mode: {
    name: 'fn_field_mode_suivi',
    class: 'js-callToActionFormFollowMode'
  },
  seed_select_modes: {
    name: 'fn_field_conseils_selection',
    class: 'js-callToActionFormSeedSelectModes'
  },
  internet_usage: {
    name: 'fn_field_utilisation_internet',
    class: 'js-callToActionFormInternetUsage'
  },
  city: {
    name: 'fn_field_ville',
    class: 'js-callToActionFormCity'
  },
  address: {
    name: 'fn_field_adresse',
    class: 'js-callToActionFormAddress'
  },
  email_submit: {
    name: 'email_submit',
    class: 'js-btnEmailValidation'
  },
  submit: {
    name: 'submit',
    class: 'js-callToActionFormSubmit'
  },
  form: {
    name: 'form',
    class: 'js-callToActionForm'
  },
  form_container: {
    name: 'form_container',
    class: 'js-ctaFormContainer'
  },
  content: {
    name: 'content',
    class: 'js-callToActionContent'
  }
};

export default FIELDS;
