import '@splidejs/splide/dist/css/splide.min.css';

import Splide from '@splidejs/splide';

const Testimonial = () => {
  const testimonialList = document.querySelector('.js-testimonialList');

  if (!testimonialList) return;

  const { lastElementChild } = testimonialList;

  const value = lastElementChild?.getBoundingClientRect().width;

  const CONFIG_SPLIDE = {
    arrows: false,
    pagination: true,
    drag: true,
    perPage: 1,
    perPane: 1,
    swipeDistanceThreshold: value,
    autoHeight: true
  };

  if (document.querySelector(`#tb-blockTestimonial`)) {
    const splide = new Splide(`#tb-blockTestimonial`, CONFIG_SPLIDE);
    splide.mount();
  }
};

export default Testimonial;
