import 'flatpickr/dist/flatpickr.css';

// uniquement if pikcupdate
const SVG_ARROW_NEXT = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9.70492 5.99992L8.29492 7.40992L12.8749 11.9999L8.29492 16.5899L9.70492 17.9999L15.7049 11.9999L9.70492 5.99992Z"/></svg>`;

const SVG_ARROW_PREVIOUS = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.2951 17.9999L15.7051 16.5899L11.1251 11.9999L15.7051 7.40992L14.2951 5.99992L8.29508 11.9999L14.2951 17.9999Z"/></svg>`;

const PICKER_CONFIG = {
  monthSelectorType: 'static',
  mode: 'single',
  dateFormat: 'd/m/Y',
  position: 'below center',
  nextArrow: SVG_ARROW_NEXT,
  prevArrow: SVG_ARROW_PREVIOUS,
  allowInput: true,
  onOpen: [
    (selectedDates, dateStr, instance) => {
      instance.input.classList.add('open');
    }
  ],
  onClose: (selectedDates, dateStr, instance) => {
    instance.input.classList.remove('open');
  }
};

const PickupDate = async () => {
  const inputPickers = document.querySelectorAll('.PickupDate');

  if (inputPickers.length > 0) {
    const { default: flatpickr } = await import('flatpickr');

    const { French } = await import('flatpickr/dist/l10n/fr.js');

    // set picker to french
    // todo get local to change this.
    flatpickr.localize(French);

    inputPickers.forEach((inputPicker) => {
      const minDate = inputPicker.dataset.mindate;
      const maxDate = inputPicker.dataset.maxdate;
      const openDate = inputPicker.dataset.opendate;

      const config = {
        ...PICKER_CONFIG,
        ...(minDate ? { minDate } : {}),
        ...(maxDate ? { maxDate } : {})
      };

      const fp = flatpickr(inputPicker, config);

      if (!inputPicker.value && openDate) {
        fp.jumpToDate(openDate);
      }
    });
  }
};

export default PickupDate;
