const SELECTED = 'selected';
const PARENT_ID = '.Rebound';
const NAV_ITEM = '.Rebound-nav-item';

const updateContent = (reboundNavItems) => {
  reboundNavItems.forEach((item) => {
    if (item.classList.contains(SELECTED)) {
      const reboundContent = document.querySelector(`#${item.dataset.id}`);

      reboundContent.classList.add(SELECTED);
    } else {
      const reboundContent = document.querySelector(`#${item.dataset.id}`);

      if (reboundContent && reboundContent.classList.contains(SELECTED)) {
        reboundContent.classList.remove(SELECTED);
      }
    }
  });
};

const Rebound = async () => {
  const rebounds = document.querySelectorAll(PARENT_ID);

  rebounds.forEach((rebound) => {
    const reboundNavItems = rebound.querySelectorAll(NAV_ITEM);

    updateContent(reboundNavItems);

    reboundNavItems.forEach((item) => {
      if (item.classList.contains('no-js')) {
        item.classList.remove('no-js');
      }

      item.addEventListener('click', ({ currentTarget }) => {
        if (!currentTarget.classList.contains(SELECTED)) {
          const oldSelected = document.querySelector(
            `#${rebound.id}>.Rebound-nav>${NAV_ITEM}.${SELECTED}`
          );

          if (oldSelected) {
            oldSelected.classList.remove(SELECTED);
          }

          currentTarget.classList.add(SELECTED);

          updateContent(reboundNavItems);
        }
      });
    });
  });
};

export default Rebound;
